// import Swiper JS
import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
// import Swiper styles
import "swiper/css/bundle";

const carousel = new Swiper(".swiper-home", {
  modules: [Pagination, Navigation, Autoplay],
  // Optional parameters
  loop: true,
  slidesPerView: 1,
  allowTouchMove: true,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    el: ".swiper-pagination-home",
    type: "bullets",
    clickable: true,
  },

  navigation: {
    nextEl: ".swiper-button-next-home",
    prevEl: ".swiper-button-prev-home",
  },
});
